.coupon-card {
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .coupon-card.enabled {
    border-left: 4px solid #28a745; /* Green border for enabled */
  }
  
  .coupon-card.disabled {
    border-left: 4px solid #dc3545; /* Red border for disabled */
  }
  
  .coupon-icon {
    font-size: 24px;
    color: #ff6f61;
  }
  
  .admin-controls {
    display: flex;
    align-items: center;
  }
  
  .form-check-label {
    font-size: 14px;
  }
  
  .form-check-input:checked {
    background-color: #28a745; /* Green for enabled */
  }
  
  .form-check-input:not(:checked) {
    background-color: #dc3545; /* Red for disabled */
  }
  