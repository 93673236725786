#form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  #input-file-upload {
    display: none;
  }

  #input-file-upload1 {
    display: none;
  }

  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
  }

  #label-file-upload1 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
  }

  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }

  .upload-button:hover {
    text-decoration-line: underline;
  }
  .create-food-container .image-box{
    height: 150px;
    width: 150px;
    background-color: #cbd5e1;
    border-radius: 5px;
    overflow: hidden;
  }
  .create-food-container .image-box img{
    width: 100%;
height: 145px;
    max-width: 150px;
    object-fit: cover;
    border-radius: 5px;
  }
  .create-food-container .image-box .img{
    position: relative;
  }
  .create-food-container .image-box .img-delete{
    position: absolute;
    right: 0;
    top: 0;
    background-color: #cbd5e156;
    padding: 7px;
  }